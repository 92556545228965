import React from 'react'
import { Typography } from '@mui/material'
import { useFormatMessage } from '@hooks/util'
import { InfoIconWithTooltip } from '../../InfoIconWithTooltip'
import TypographyWithIcon from '../../TypographyWithIcon'
import SummaryCard from './SummaryCard'

type StatisticCardProps = {
  labelKey: string
  value: string
}

function StatisticCard({ labelKey, value }: StatisticCardProps) {
  const formatMessage = useFormatMessage()

  return (
    <SummaryCard
      gutterBottom
      title={
        <TypographyWithIcon
          variant="subtitle3"
          icon={<InfoIconWithTooltip tooltip={formatMessage(`${labelKey}_tooltip`)} />}
        >
          {formatMessage(`${labelKey}_label`)}
        </TypographyWithIcon>
      }
    >
      <Typography variant="h4">{value}</Typography>
    </SummaryCard>
  )
}

export default StatisticCard

import { Plugin } from 'chart.js'
import { $primaryFont } from '../../dependencies/variables'

const config = {
  padding: 12,
  firstLine: {
    fontSize: 16,
    fontWeight: 500,
  },
  secondLine: {
    fontSize: 14,
    fontWeight: 400,
  },
}

const firstLineFont = `${config.firstLine.fontWeight} ${config.firstLine.fontSize}px ${$primaryFont}`
const secondLineFont = `${config.secondLine.fontWeight} ${config.secondLine.fontSize}px ${$primaryFont}`

function buildFillTextWithFont(ctx: CanvasRenderingContext2D) {
  return (font: string, action: () => void) => {
    ctx.save()
    ctx.font = font
    action.call(ctx)
    ctx.restore()
  }
}

const BoldXAxis: Plugin<'line'> = {
  id: 'bold-x-axis',
  afterDraw(chart) {
    const ctx = chart.ctx
    const fill = buildFillTextWithFont(ctx)

    // Clear existing labels
    ctx.clearRect(0, chart.scales.y.bottom + config.padding / 2, chart.width, 50)

    chart.scales.x.ticks.forEach((tick, index) => {
      // we're adding text size because alignment is based on the bottom of the text
      const y = chart.scales.y.bottom + config.firstLine.fontSize + config.padding / 2
      const x = chart.scales.x.getPixelForTick(index) + config.padding
      const label = tick.label

      if (!Array.isArray(label)) {
        fill(secondLineFont, () => ctx.fillText(label || '', x, y))
        return
      }

      fill(firstLineFont, () => ctx.fillText(label[0], x, y))
      fill(secondLineFont, () => ctx.fillText(label[1], x, y + config.firstLine.fontSize))
    })
  },
}

export default BoldXAxis

import isString from 'lodash/isString'

export const getFormattedPartialUID = (id: unknown) => {
  if (!id || !isString(id)) {
    return ''
  }

  const _id = id.substring(0, 8)

  return _id.toUpperCase()
}
export const cleanImageTags = (html: string) => html.replace(/(<p><img[^>]*>[^>]+\/p>)|(<img[^>]*>)/g, '')
export function buildNumberFormatter(locale: string) {
  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    useGrouping: true,
  }).format
}
export function formatNumberCompact(value: string | number): string {
  // hardcoded en here because 'K' notation is available only in en locale
  return new Intl.NumberFormat('en', { notation: 'compact' }).format(Number(value))
}
export function formatCurrency(locale: string, value: string | number, overrides?: Intl.NumberFormatOptions): string {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...overrides,
  }).format(Number(value))
}
export function formatPercentage(locale: string, value: string | number, overrides?: Intl.NumberFormatOptions): string {
  return new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...overrides,
  }).format(Number(value))
}
export function formatDecimal(locale: string, value: string | number, overrides?: Intl.NumberFormatOptions): string {
  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...overrides,
  }).format(Number(value))
}

import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@mui/material'
import { formatCurrency, formatDecimal, formatPercentage } from '../../../utils/formatters'
import StatisticCard from './StatisticCard'

const mockData = {
  cpa: 76.33333333,
  views_to_applications: 0.080000000002,
  jobs_live_count: 150,
  previous_period_cpa: 14.2333333,
  previous_period_views_to_applications: 0.0733333333,
  linkout_exists: true,
  target_live_jobs_count: 200,
  average_quality_score: 0.7555555555555555,
  views: 10_000,
  application_started_count: 1_200,
  application_submitted_count: 900,
  application_sent_count: 800,
  cta_flags: ['increase_quality_score', 'publish_more_jobs'],
}

function NumbersSection() {
  const intl = useIntl()
  const delivery = mockData.linkout_exists ? 'lo' : 'nlo'

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <StatisticCard
        labelKey={`reach_stats_summary_section_cpa_${delivery}`}
        value={formatCurrency(intl.locale, mockData.cpa)}
      />
      <StatisticCard
        labelKey={`reach_stats_summary_section_atv_${delivery}`}
        value={formatPercentage(intl.locale, mockData.views_to_applications)}
      />
      <StatisticCard
        labelKey="reach_stats_summary_section_live_jobs"
        value={formatDecimal(intl.locale, mockData.jobs_live_count)}
      />
    </Box>
  )
}

export default NumbersSection

import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { $colors } from '../../dependencies/variables'
import { LegendItem } from './types'

type Props = {
  items: LegendItem[]
}

function LegendIcon({ item }: { item: LegendItem }) {
  const iconStyles = {
    line: { height: 2, bgcolor: item.color },
    dashedLine: { height: 0, borderTop: `2px dashed ${item.color}` },
    default: { height: 16, bgcolor: item.color },
  }

  return <Box width={40} sx={iconStyles[item.type] || iconStyles.default} />
}

function CustomLegend({ items }: Props) {
  return (
    <Box display="flex" justifyContent="center" my={2}>
      {items.map((item, index) => (
        <Box key={index} display="flex" alignItems="center" mr={2}>
          <LegendIcon item={item} />
          <Typography variant="body1" ml={1} color={$colors.text.primary}>
            {item.label}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

export default CustomLegend

import React, { useCallback, useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import Box from '@mui/material/Box'
import { ChartOptions, ChartData } from 'chart.js'
import { useCurrentRecruiter } from '@hooks/store'
import { useFormatMessage } from '@hooks/util'
import { DEFAULT_LOCALE } from '../../../../../cypress/support/formatMessage'
import { HexColor } from '../../../types'
import { buildNumberFormatter } from '../../../utils/formatters'
import BoldXAxis from './BoldXAxis'

const colors: HexColor[] = ['#CED9EF', '#9EB4DF', '#3D69BE', '#182A4C']

const xAxisFields: string[] = [
  'views',
  'application_started_count',
  'application_submitted_count',
  'application_sent_count',
]

const mockData = {
  views: 0,
  application_started_count: 0,
  application_submitted_count: 0,
  application_sent_count: 0,
}

export default function CampaignFunnel() {
  const recruiter = useCurrentRecruiter()
  const formatMessage = useFormatMessage()
  const formatNumber = useCallback(
    (number: number) => buildNumberFormatter(recruiter?.locale || DEFAULT_LOCALE)(number),
    [recruiter?.locale]
  )
  const stats = useMemo(() => {
    return xAxisFields.map((field, index) => {
      const value = mockData[field]
      const data = {
        color: colors[index],
        yAxis: new Array(3).fill(value),
        xAxis: new Array(3).fill(''),
      }

      data.xAxis[0] = [formatNumber(value), formatMessage('reach_stats_funnel_chart_label_' + field)]

      return data
    })
  }, [formatMessage, formatNumber])

  const data: ChartData<'line'> = {
    datasets: [
      {
        data: stats.flatMap((stat) => stat.yAxis),
        fill: true,
        borderWidth: 0,
        pointRadius: 0,
        segment: {
          backgroundColor(ctx) {
            const index = Math.floor(ctx.p0DataIndex / 3)
            return colors[index] || colors[colors.length - 1]
          },
        },
      },
    ],
  }

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: { display: false },
      legend: { display: false },
    },
    scales: {
      y: {
        type: 'linear',
        beginAtZero: true,
      },
      x: {
        type: 'category',
        grid: { display: false },
        labels: stats.flatMap((stat) => stat.xAxis),
        ticks: {
          minRotation: 0,
          maxRotation: 0,
          autoSkip: false,
        },
      },
    },
  }

  return (
    <Box height={234}>
      <Line data={data} options={options} plugins={[BoldXAxis]} />
    </Box>
  )
}

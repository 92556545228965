import React from 'react'
import { Box } from '@mui/material'
import CTASection from './CTASection'
import NumbersSection from './NumbersSection'

function Summary() {
  return (
    <Box display="grid" gridTemplateColumns="4.4fr 7.6fr" gap={2}>
      <NumbersSection />
      <CTASection />
    </Box>
  )
}

export default Summary

import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Typography, IconButton, Button } from '@mui/material'
import RowFlex from '../../shared/RowFlex'

type CTAProps = {
  title: string
  description: string
  actionLabel?: string
  actionIcon?: React.ReactNode
  onAction?: () => void
  onDismiss: () => void
}

function CTA({ title, description, actionLabel, actionIcon, onAction, onDismiss }: CTAProps) {
  return (
    <Box
      data-testid={`cta-${title}`}
      sx={{
        position: 'relative',
        py: 1,
        px: 2,
        bgcolor: 'common.white',
        borderRadius: 2,
      }}
    >
      <IconButton
        size="small"
        onClick={onDismiss}
        sx={{ position: 'absolute', top: 8, right: 8 }}
        data-testid="cta-close-btn"
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <Typography variant="body2" gutterBottom>
        {title}
      </Typography>
      <RowFlex justifyContent="space-between">
        <Typography variant="caption" color="text.secondary">
          {description}
        </Typography>
        {onAction && (
          <Button
            data-testid="cta-action-btn"
            startIcon={actionIcon}
            onClick={onAction}
            sx={{ flexShrink: 0 }}
            size="small"
          >
            {actionLabel}
          </Button>
        )}
      </RowFlex>
    </Box>
  )
}

export default CTA

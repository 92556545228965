import React from 'react'
import EmailOutlined from '@mui/icons-material/EmailOutlined'
import { Box, Typography } from '@mui/material'
import { useFormatMessage } from '@hooks/util'
import CTA from './CTA'
import SummaryCard from './SummaryCard'

const mockCTAs = [
  {
    title: 'Increase your Job Ad Quality Score',
    description:
      'Including additional data in your feed helps us attract even more qualified candidates. Reach out to discuss how this can be achieved.',
    actionLabel: 'get in touch',
    actionIcon: <EmailOutlined />,
    onDismiss() {
      // eslint-disable-next-line no-console
      console.log('onDismiss called for #1')
    },
    onAction() {
      // eslint-disable-next-line no-console
      console.log('onAction called for #1')
    },
  },
  {
    title: 'Publish more jobs with your campaign',
    description:
      "Undercutting the target set for number of jobs published can constrain your campaign. By adding additional jobs to your campaign's feed, you can directly enhance performance.",
    actionLabel: 'get in touch',
    actionIcon: <EmailOutlined />,
    onDismiss() {
      // eslint-disable-next-line no-console
      console.log('onDismiss called for #2')
    },
  },
  {
    title: 'Reach out to discuss additional recommendations',
    description: 'Reach out to discuss additional recommendations',
    actionLabel: 'get in touch',
    actionIcon: <EmailOutlined />,
    onDismiss() {
      // eslint-disable-next-line no-console
      console.log('onDismiss called for #3')
    },
    onAction() {
      // eslint-disable-next-line no-console
      console.log('onAction called for #3')
    },
  },
]

function CTASection() {
  const formatMessage = useFormatMessage()

  return (
    <SummaryCard title={formatMessage('reach_stats_summary_ctas_title')}>
      <Typography variant="body2">{formatMessage('reach_stats_summary_ctas_description')}</Typography>
      <Box display="flex" flexDirection="column" gap={1.5} my={1.5}>
        {mockCTAs.map((cta) => (
          <CTA key={cta.title} {...cta} />
        ))}
      </Box>
    </SummaryCard>
  )
}

export default CTASection

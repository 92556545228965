import React from 'react'
import { Chart } from 'react-chartjs-2'
import { useIntl } from 'react-intl'
import Box from '@mui/material/Box'
import { ChartData, ChartOptions } from 'chart.js'
import { useFormatMessage } from '@hooks/util'
import { DateRange } from '../../../types'
import { formatCurrency } from '../../../utils/formatters'
import { $colors } from '../../dependencies/variables'
import CustomLegend from './CustomLegend'
import { LegendItem } from './types'

interface LiveJobsVsCpaChartData {
  date_ranges: DateRange[]
  live_jobs_counts: number[]
  cpa_values: number[]
  live_jobs_target: number
}

const mockData: LiveJobsVsCpaChartData = {
  date_ranges: [],
  live_jobs_counts: [],
  cpa_values: [],
  live_jobs_target: 0,
}

export default function LiveJobsVsCpaChart() {
  const intl = useIntl()
  const formatMessage = useFormatMessage()

  const chartData: ChartData = {
    datasets: [
      {
        type: 'line',
        data: mockData.live_jobs_counts,
        borderColor: $colors.primary[500],
        borderWidth: 2,
        backgroundColor: $colors.primary[500],
        yAxisID: 'y-live-jobs-scale',
        pointRadius: 0,
        animation: false,
        datalabels: { display: false },
      },
      {
        type: 'bar',
        data: [],
        yAxisID: 'y-live-jobs-target-scale',
        datalabels: { display: false },
      },
      {
        type: 'bar',
        data: mockData.cpa_values,
        backgroundColor: $colors.primary[12],
        yAxisID: 'y-cpa-scale',
        barThickness: 36,
        datalabels: {
          align: 'top',
          anchor: 'end',
          color: $colors.text.darkPrimary,
          formatter(value) {
            return formatCurrency(intl.locale, value)
          },
        },
      },
    ],
  }

  const options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      'x': {
        display: true,
        type: 'category',
        grid: {
          display: false,
        },
        labels: mockData.date_ranges.map((date_range) => date_range.from),
        ticks: {
          labelOffset: 18,
          crossAlign: 'near',
        },
      },
      'y-live-jobs-scale': {
        display: true,
        suggestedMax: mockData.live_jobs_target * 1.1,
        type: 'linear',
        beginAtZero: true,
        grace: '10%',
      },
      'y-cpa-scale': {
        display: false,
        position: 'right',
        type: 'linear',
        grace: '20%',
      },
      'y-live-jobs-target-scale': {
        display: false,
      },
    },
    plugins: {
      // @ts-expect-error
      'breakable-y-axis': false,
      'annotation': {
        annotations: {
          targetLine: {
            type: 'line',
            yMin: mockData.live_jobs_target,
            yMax: mockData.live_jobs_target,
            borderColor: $colors.primary[500],
            borderWidth: 1,
            yScaleID: 'y-live-jobs-scale',
            borderDash: [5, 5], // Dashed line
          },
        },
      },
      'tooltip': {
        callbacks: {
          title(tooltipItems) {
            const index = tooltipItems[0].dataIndex
            const dateRange = mockData.date_ranges[index]
            return `${dateRange.from} - ${dateRange.to}`
          },
        },
      },
      'legend': {
        display: false,
      },
    },
  }

  const legend: LegendItem[] = [
    {
      type: 'line',
      color: $colors.primary[500],
      label: formatMessage('reach_stats_cpa_chart_label_live_jobs'),
    },
    {
      type: 'dashedLine',
      color: $colors.primary[500],
      label: formatMessage('reach_stats_funnel_chart_label_campaign_target'),
    },
    {
      type: 'rectangle',
      color: $colors.primary[12],
      label: formatMessage('reach_stats_funnel_chart_label_cpa'),
    },
  ]

  return (
    <Box height={234}>
      <CustomLegend items={legend} />
      <Chart type="line" data={chartData} options={options} />
    </Box>
  )
}
